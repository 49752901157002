import type { MetroStore } from '../types/mpay.types';

export enum MPayApi {
  CREDIT_LIMITS = 'credit-limits',
  CREDIT_SETTLE = 'mcredit/credit-settle',
  INVOICES = 'invoices',
  METRO_STORES = 'stores',
  PDF_PROXY = 'invoice',
  PERSON_DETAILS = 'person-details',
  PAYMENTS = 'payments',
  PROFILES = 'profiles',
}

export enum MethodType {
  GET = 'GET',
  POST = 'POST',
}

export enum DateRange {
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  THIS_QUARTER = 'this_quarter',
  THIS_YEAR = 'this_year',
  LAST_YEAR = 'last_year',
  THIS_AND_LAST_YEAR = 'this_and_last_year',
}

export enum InvoiceType {
  LEGA = 'LEGA',
  PRIV = 'PRIV',
}

export enum CustomerInvoiceType {
  COD = 'COD',
  EMPTIES = 'EMPTIES',
  INVOICE = 'INVOICE',
  POD = 'PAYMENT_RECEIPT',
  RETURN = 'RETURN',
  VOIDING_INVOICE = 'VOIDING_INVOICE',
}

export enum CreditSettleFrequency {
  EMPTY = '',
  MEDIU14 = '14 MEDIU',
  MEDIU20 = '20 MEDIU',
  MEDIU21 = '21 MEDIU',
  MEDIU26 = '26 MEDIU',
  MEDIU30 = '30 MEDIU',
  MEDIU45 = '45 MEDIU',
  FIX15 = '15 FIX',
  FIX30 = '30 FIX',
}

export enum PaymentStatus {
  DUE = 'due',
  OVERDUE = 'overdue',
  PARTIALLY_REPAID = 'partially-repaid',
  REPAID = 'repaid',
}

// this is the error we get from Cloud Armour when there are too many requests
// as it does not return an error code, the request just fails, here's what it returns
export const ERR_429 = 'TypeError: Failed to fetch';

export const CARD_CODE = '300';
export const MCREDIT_CODE = '600';
export const ACCOUNT_ID_ITEM = 'accountId';
export const COUNTRY_ITEM = 'country';
export const TOKEN_ITEM = 'token';

// Payment codes per their occurence order (from most common to )
export const paymentCodes = {
  300: '300',
  1: '1',
  600: '600',
  301: '301',
  403: '403',
  620: '620',
  700: '700',
  400: '400',
  500: '500',
  655: '655',
  424: '424',
  200: '200',
  210: '210',
  801: '801',
  302: '302',
  699: '699',
  603: '603',
  611: '611',
  10001: '10001',
  304: '304',
  811: '811',
  444: '444',
  401: '401',
  303: '303',
  610: '610',
  410: '410',
  950: '950',
  475: '475',
  1804: '1804',
  206: '206',
  1801: '1801',
  425: '425',
  1803: '1803',
  1100: '1100',
  901: '901',
  511: '511',
  411: '411',
  250: '250',
  602: '602',
  382: '382',
  923: '923',
  415: '415',
  320: '320',
  308: '308',
  1700: '1700',
  927: '927',
  413: '413',
  900: '900',
  657: '657',
  9990: '9990',
  926: '926',
  656: '656',
  615: '615',
  208: '208',
  306: '306',
  1000: '1000',
  404: '404',
  951: '951',
  1300: '1300',
  443: '443',
  445: '445',
  805: '805',
  1200: '1200',
  929: '929',
  420: '420',
  321: '321',
  616: '616',
  902: '902',
  998: '998',
  1903: '1903',
  451: '451',
  505: '505',
  803: '803',
  402: '402',
  480: '480',
  477: '477',
  930: '930',
  802: '802',
  474: '474',
  1102: '1102',
  501: '501',
  910: '910',
  940: '940',
  406: '406',
  653: '653',
  652: '652',
  407: '407',
  450: '450',
  412: '412',
  476: '476',
  1101: '1101',
  442: '442',
  408: '408',
  421: '421',
  928: '928',
  922: '922',
  381: '381',
  920: '920',
  409: '409',
  2004: '2004',
  907: '907',
  1802: '1802',
  419: '419',
  481: '481',
  619: '619',
  502: '502',
  478: '478',
  452: '452',
  905: '905',
  921: '921',
  423: '423',
  658: '658',
  650: '650',
  1809: '1809',
  482: '482',
  906: '906',
  414: '414',
  604: '604',
  509: '509',
  939: '939',
  503: '503',
  1806: '1806',
  205: '205',
  1901: '1901',
  1805: '1805',
  9991: '9991',
  908: '908',
  651: '651',
  1103: '1103',
  2001: '2001',
  2000: '2000',
  1600: '1600',
  453: '453',
  904: '904',
  1500: '1500',
  2002: '2002',
  2005: '2005',
  903: '903',
  209: '209',
  1201: '1201',
  812: '812',
  819: '819',
  814: '814',
  350: '350',
  804: '804',
  10000: '10000',
  810: '810',
  10002: '10002',
  800: '800',
} as const;

export type PaymentCode = keyof typeof paymentCodes;

export const metroStores: MetroStore[] = [
  {
    name: 'Arad',
    id: 34,
  },
  {
    name: 'Bacău',
    id: 16,
  },
  {
    name: 'Baia Mare',
    id: 25,
  },
  {
    name: 'Brașov 1',
    id: 13,
  },
  {
    name: 'Brașov 2',
    id: 30,
  },
  {
    name: 'Băneasa',
    id: 32,
  },
  {
    name: 'Berceni',
    id: 27,
  },
  {
    name: 'Militari',
    id: 11,
  },
  {
    name: 'Pallady',
    id: 36,
  },
  {
    name: 'Voluntari',
    id: 18,
  },
  {
    name: 'Buzău',
    id: 40,
  },
  {
    name: 'Cluj',
    id: 15,
  },
  {
    name: 'Constanța 1',
    id: 14,
  },
  {
    name: 'Constanța 2',
    id: 31,
  },
  {
    name: 'Craiova',
    id: 17,
  },
  {
    name: 'Deva',
    id: 35,
  },
  {
    name: 'Galați',
    id: 24,
  },
  {
    name: 'Iași',
    id: 20,
  },
  {
    name: 'Oradea',
    id: 19,
  },
  {
    name: 'Piatra Neamț Punct',
    id: 54,
  },
  {
    name: 'Pitești',
    id: 28,
  },
  {
    name: 'Ploiești',
    id: 22,
  },
  {
    name: 'Satu Mare Punct',
    id: 53,
  },
  {
    name: 'Sibiu',
    id: 21,
  },
  {
    name: 'Suceava',
    id: 26,
  },
  {
    name: 'Târgoviște Punct',
    id: 56,
  },
  {
    name: 'Târgu Mureș',
    id: 23,
  },
  {
    name: 'Timișoara 1',
    id: 12,
  },
  {
    name: 'Timișoara 2',
    id: 29,
  },
  {
    name: 'Turda Punct',
    id: 58,
  },
];
