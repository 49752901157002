import { writable } from 'svelte/store';
import { bodyScrollLock } from './UiStore';

type ErrorDialogState = {
  showDialog: boolean;
  actions: Array<() => void>;
};

// retries failed API calls with a single dialog retry action
const createErrorDialogStore = () => {
  const initialState = {
    showDialog: false,
    actions: [],
  };
  const { subscribe, update, set } = writable<ErrorDialogState>(initialState);

  return {
    subscribe,
    setRetryAction: (action: () => void) => {
      bodyScrollLock.set(true);

      update((state) => ({
        ...state,
        showDialog: true,
        actions: [...state.actions, action],
      }));
    },
    reset: () => {
      bodyScrollLock.set(false);

      set(initialState);
    },
  };
};

export const errorDialogStore = createErrorDialogStore();
