import { get } from 'svelte/store';
import { MethodType, TOKEN_ITEM } from '../constants';
import { settingsStore } from '../../store/settingsStore';

/**
 * Sends fetch request to the given endpoint with the request details.
 */
export async function sendRequest<T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<T> {
  try {
    const settings = get(settingsStore);
    const { country, accountId, token } = settings.credentials;

    const headers = new Headers({
      'Content-Type': 'application/json',
      ...init?.headers,
    });

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(input, {
      ...init,
      headers,
    });

    if (!response.ok) {
      // TODO: remove the test mode check once we have proper e2e tests
      if (response.status === 401 && import.meta.env.MODE !== 'test') {
        window.localStorage.removeItem(TOKEN_ITEM);
        window.location.href = `${window.location.origin}?country=${country}&accountId=${accountId}`;
        throw new Error(`HTTP status: ${response.status}! Resetting the App!`);
      }

      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // handle POST 201 response case with no body
    if (response.status === 201) {
      return {} as T;
    }

    return (await response.json()) as T;
  } catch (error) {
    if (error instanceof TypeError) {
      console.warn('Network error:', error);
    } else if ((error as Error).name === 'AbortError') {
      console.warn('Request timeout:', error);
    } else {
      console.error('HTTP error:', error);
    }

    throw error; // re-throwing the error to be handled by the caller
  }
}

export type Request = {
  url: string;
  method?: MethodType | string;
  data?: BodyInit | null | undefined;
};
