export const isAndroid = (): boolean => {
  const userAgent = navigator.userAgent || '';
  return (
    /android/i.test(userAgent) ||
    /Linux.*Mobile/.test(userAgent) ||
    typeof window.JSBridge !== 'undefined'
  );
  // we need to wait for the Android team to implement the JSBridge
  // return !!window.JSBridge;
};

export const isIos = (): boolean => {
  const userAgent = navigator.userAgent || '';
  return (
    /iPhone|iPad|iPod/.test(userAgent) ||
    (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent) &&
      navigator.maxTouchPoints > 0)
  );
};

export const isIosWebView = (): boolean => {
  const userAgent = navigator.userAgent || '';
  const hasWebkitHandlers = !!window.webkit?.messageHandlers;
  const isThirdPartyBrowser = /CriOS|FxiOS|EdgiOS/.test(userAgent);
  const isSafari = /Safari/.test(userAgent) || userAgent.includes('safari');

  return isIos() && hasWebkitHandlers && !isSafari && !isThirdPartyBrowser;
};

export const isAndroidWebView = (): boolean => {
  const userAgent = navigator.userAgent || '';
  return (
    isAndroid() && (/wv/.test(userAgent) || /Version\/\d+\.\d+/.test(userAgent))
  );
};

export const isWebView = (): boolean => {
  return isIosWebView() || isAndroidWebView();
};
