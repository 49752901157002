<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { posthog } from '../../plugins/posthog';
  import { routes } from '../../routes';
  import type { Invoice } from '../../types/mpay.types';
  import { navigate } from 'svelte-routing';
  import { t } from 'svelte-i18n';
  import {
    getFormattedNumber,
    getFormattedTimestamp,
  } from '../utils/formatters';
  import { onMount } from 'svelte';
  import Icon from '../components/Icon.svelte';
  import type { IconName } from '../../types/icons.types';
  import { getInvoiceById } from '../utils/invoices';
  import { getMetroStoreById } from '../utils/metroStores';
  import { getCurrencyUnit } from '../utils/helpers';
  import { CustomerInvoiceType, InvoiceType } from '../constants';
  import { topBar } from '../../store/TopBarStore';
  import { Context, Target } from '../utils/analytics/keys';
  import { invoicesStore } from '../../store/invoicesStore';
  import Button from '../components/Button.svelte';
  import { isAndroidWebView } from '../utils/detectPlatform';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { mCreditStore } from '../../store/mCreditStore';
  import InvoicePaymentStatus from '../components/InvoicePaymentStatus.svelte';
  import InvoicePaymentMethods from '../components/InvoicePaymentMethods.svelte';
  import InvoiceInBottomSheet from '../components/InvoiceInBottomSheet.svelte';

  type InvoiceDetail = {
    label: string;
    value: string | number;
    iconName: IconName;
  };

  export let id = '';
  let invoice: Invoice | null = null;
  let invoiceDetails: InvoiceDetail[] = [];
  let isPod: boolean;
  let isCod: boolean;
  let isPodInvoiceOpened = false;

  const getNonPodInvoiceDetails = (invoice: Invoice): InvoiceDetail[] => [
    {
      label: $t('invoice_details.net_amount'),
      value:
        getFormattedNumber(invoice.netAmount) +
        ' ' +
        getCurrencyUnit(invoice.currencyCode),
      iconName: 'CashPaymentCoins',
    },
    {
      label: $t('invoice_details.tax'),
      value:
        getFormattedNumber(invoice.vatAmount) +
        ' ' +
        getCurrencyUnit(invoice.currencyCode),
      iconName: 'Tax',
    },
  ];

  posthog.capture('$pageview');

  onMount(() => {
    invoice =
      getInvoiceById($invoicesStore.invoices, id) ||
      getInvoiceById($mCreditStore.invoices, id);
    if (!invoice) return navigate(routes.home.path);

    topBar.setContext(Context.InvoiceDetails);
    topBar.setTitle($t('invoice_details.invoice'));
    topBar.setShowBackButton(true);

    isPod = invoice.customerInvoiceType === CustomerInvoiceType.POD;
    isCod = invoice.customerInvoiceType === CustomerInvoiceType.COD;
    // List items for the following invoice details
    invoiceDetails = [
      {
        label: isCod
          ? $t('invoice_details.cod_invoice')
          : $t('invoice_details.invoice'),
        value: invoice.number,
        iconName: 'Invoices',
      },
      {
        label: $t('invoice_details.gross_amount'),
        value:
          getFormattedNumber(invoice.grossAmount) +
          ' ' +
          getCurrencyUnit(invoice.currencyCode),
        iconName: 'PriceTags',
      },
      ...(!isPod ? getNonPodInvoiceDetails(invoice) : []),
      {
        label: $t('invoice_details.timestamp'),
        value: getFormattedTimestamp(invoice.date) || '',
        iconName: 'Calendar',
      },
      {
        label: $t('invoice_details.invoice_type'),
        value: $t(`invoice_types.${invoice.invoiceType}`),
        iconName:
          invoice.invoiceType === InvoiceType.LEGA ? 'Business' : 'Cardholder',
      },
      {
        label: $t('invoice_details.merchant'),
        value: getMetroStoreById(invoice.storeId) || $t('general.unknown'),
        iconName: 'Shop',
      },
      {
        label: $t('invoice_details.card_holder'),
        value: invoice.cardHolder.firstName + ' ' + invoice.cardHolder.lastName,
        iconName: 'SingleManCircle',
      },
    ];

    invoiceDetails.splice(isPod ? 4 : 6, 0, {
      label: $t('invoice_details.type_of_purchase'),
      value: isPod || isCod ? $t('invoice.delivery') : $t('invoice.in_store'),
      iconName: isPod || isCod ? 'Delivery' : 'StoreMakro',
    });
  });

  const goBack = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: $topBar.context,
      target: Target.BackToInvoiceButton,
    });
    window.history.back();
  };

  const togglePodInvoice = () => {
    isPodInvoiceOpened = !isPodInvoiceOpened;
  };
</script>

<Layout>
  {#if invoice}
    {#if invoice.mCreditPaymentStatus}
      <InvoicePaymentStatus
        paymentStatus={invoice.mCreditPaymentStatus}
        dueDate={invoice.dueDate}
      />
    {/if}

    <div class="my-4 px-5" data-cy="invoice-details-list">
      <section class="mb-4">
        <p
          class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
        >
          {!isPod
            ? $t('invoice_details.title')
            : $t('invoice_details.pod_title')}
        </p>

        <ul>
          {#each invoiceDetails as invoice}
            <li
              class="flex gap-3 border-b border-primary-tint90 py-[14px] text-primary-base last:border-none"
            >
              <div class="rounded-lg bg-semantic-appBackground p-2.5">
                <Icon iconName={invoice.iconName} />
              </div>

              <div class="inline-flex flex-col">
                <p
                  class="text-android-title-medium font-medium ios:text-ios-headline ios:font-semibold"
                >
                  {invoice.value}
                </p>
                <p
                  class="text-android-body-small text-primary-tint40 ios:text-ios-footnote"
                >
                  {invoice.label}
                </p>
              </div>
            </li>
          {/each}
        </ul>
      </section>

      {#if isCod && invoice.podInvoice}
        <section class="mb-3">
          <p
            class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
          >
            {$t('invoice_details.pod_title')}
          </p>

          <button
            class="grid w-full grid-cols-[40px_minmax(0,1fr)_20px] items-center gap-3 py-[14px] text-primary-base"
            on:click={togglePodInvoice}
          >
            <div class="rounded-lg bg-semantic-appBackground p-2.5">
              <Icon iconName="Invoices" />
            </div>

            <div class="inline-flex flex-col items-start">
              <p
                class="text-android-title-medium font-medium ios:text-ios-headline ios:font-semibold"
              >
                {invoice.podInvoice.number}
              </p>
              <p
                class="text-android-body-small text-primary-tint40 ios:text-ios-footnote"
              >
                {$t('invoice_details.pod_invoice')}
              </p>
            </div>

            <Icon iconName="ChevronRight" />
          </button>
        </section>
      {/if}

      <!-- A COD doesn't have payment methods,
         but if it has a POD invoice then we show the POD's payment methods -->
      {#if isCod && invoice.podInvoice && invoice.podInvoice.paymentList?.length > 0}
        <InvoicePaymentMethods
          paymentList={invoice.podInvoice.paymentList}
          currencyCode={invoice.currencyCode}
        />
      {:else if invoice.paymentList?.length > 0}
        <InvoicePaymentMethods
          paymentList={invoice.paymentList}
          currencyCode={invoice.currencyCode}
        />
      {/if}
    </div>

    {#if isAndroidWebView()}
      <div class="mb-6 mt-4 flex flex-col gap-3 px-4">
        <Button label={$t('buttons.back')} onClick={goBack} />
      </div>
    {/if}

    {#if invoice.podInvoice}
      <InvoiceInBottomSheet
        invoice={invoice.podInvoice}
        isOpen={isPodInvoiceOpened}
        setIsOpen={togglePodInvoice}
      />
    {/if}
  {/if}
</Layout>
