<script lang="ts">
  import { errorDialogStore } from '../../store/errorDialogStore';
  import { navigateBack } from '../JSBridge/navigationController';
  import Button from './Button.svelte';
  import Icon from './Icon.svelte';
  import { t } from 'svelte-i18n';

  export let showErrorDialog = false;
  export let showIcon = false;
  export let description = '';
  export let onRetry: Array<() => void>;
  let dialog: HTMLDialogElement;

  $: if (dialog && showErrorDialog) dialog.showModal();

  const _onRetry = () => {
    onRetry.forEach((action) => action());
    dialog.close();
    errorDialogStore.reset();
  };

  const _onCancel = () => {
    navigateBack();
    dialog.close();
    errorDialogStore.reset();
  };
</script>

<dialog
  data-cy="error-dialog"
  class="absolute w-auto rounded-xl"
  bind:this={dialog}
  on:close={() => (showErrorDialog = false)}
>
  <div class="flex flex-col gap-8 pt-8">
    <div class="gap-6 px-6">
      {#if showIcon}
        <div
          class="mb-6 inline-flex rounded-lg bg-semantic-appBackground bg-opacity-95 p-2.5"
        >
          <Icon iconName="DatabaseWarning" />
        </div>
      {/if}

      <div class="flex flex-col gap-4">
        <div
          class="text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
        >
          {$t('error_dialog.title')}
        </div>
        {#if description}
          <div class="text-android-body-medium ios:text-ios-callout">
            {description}
          </div>
        {/if}
      </div>
    </div>

    <div
      class="flex w-full gap-3 border-t border-t-primary-tint90 px-5 pb-6 pt-4"
    >
      <Button
        type="outlined"
        onClick={_onCancel}
        label={$t('general.cta.back')}
      />
      <Button onClick={_onRetry} label={$t('general.cta.retry')} />
    </div>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
</style>
