<script lang="ts">
  import { t } from 'svelte-i18n';
  import image from '../../assets/images/finqware.png';
  import Icon from './Icon.svelte';
  import Button from '../components/Button.svelte';

  export let showRepaymentDialog = false;
  export let isPaymentLinkLoading: boolean;
  export let onCloseClick: () => void;
  export let onContinueClick: () => void;

  let dialog: HTMLDialogElement;

  $: if (dialog && showRepaymentDialog) dialog.showModal();
  $: if (dialog && !showRepaymentDialog) dialog.close();
</script>

<dialog
  data-cy="repayment-dialog"
  class="absolute w-auto rounded-xl"
  bind:this={dialog}
>
  <div class="relative flex flex-col p-5 pt-8">
    {#if !isPaymentLinkLoading}
      <button
        class="absolute right-4 top-4 cursor-pointer text-semantic-neutral"
        on:click={onCloseClick}
      >
        <Icon iconName="Close" size="11" />
      </button>
    {/if}

    {#if isPaymentLinkLoading}
      <div class="mb-6 flex items-center justify-center text-primary-base">
        <Icon iconName="Spinner" size="33" />
      </div>
    {:else}
      <div class="mb-6 flex justify-center">
        <div
          class="flex h-10 w-10 items-center justify-center rounded-lg bg-semantic-appBackground text-primary-base"
        >
          <Icon iconName="Shield" size="20" />
        </div>
      </div>
    {/if}

    <div
      class="px-6 pb-8 text-center text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
    >
      {isPaymentLinkLoading
        ? $t('repayment_dialog.title_redirecting')
        : $t('repayment_dialog.title_waiting')}
    </div>

    <div class="border-t border-t-primary-tint90 pt-3">
      <!-- Avoiding string interpolation and {@html} for security as translations come from external source -->
      <p
        class="mb-3 text-center font-roboto text-roboto-caption text-semantic-muted"
      >
        {$t('repayment_dialog.user_agreement_start')}

        <a
          href={$t('repayment_dialog.terms_url')}
          class="text-semantic-interaction"
          target="_blank"
          rel="noopener noreferrer"
        >
          {$t('repayment_dialog.terms_text')}
        </a>

        {$t('repayment_dialog.user_agreement_middle')}

        <a
          href={$t('repayment_dialog.policy_url')}
          class="text-semantic-interaction"
          target="_blank"
          rel="noopener noreferrer"
        >
          {$t('repayment_dialog.policy_text')}
        </a>

        {$t('repayment_dialog.user_agreement_end')}
      </p>

      <Button
        label={$t('repayment_dialog.button')}
        type="default"
        onClick={onContinueClick}
        isDisabled={isPaymentLinkLoading}
      />

      <div class="mt-3 flex">
        <p class="mr-2 font-roboto text-roboto-caption text-semantic-muted">
          {$t('repayment_dialog.description')}
        </p>

        <img class="object-contain" src={image} alt="finqware" />
      </div>
    </div>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
</style>
