import { IDAM_AUTH } from '../../env';
import { ACCOUNT_ID_ITEM, COUNTRY_ITEM, TOKEN_ITEM } from '../constants';
import { getAccountId, getCountry, getToken } from './localStorage';
import { jwtDecode } from 'jwt-decode';
import { settingsStore } from '../../store/settingsStore';

export const areCredentialsValid = (): boolean => {
  const token = getToken();
  const country = getCountry();
  const accountId = getAccountId();

  if (token && country && accountId) {
    const userData: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return userData.exp > currentTime;
  }

  return false;
};

const setCredentials = (hash: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(hash.substring(1)); // Remove the '#' from the URL
    const state = params.get('state');
    const token = params.get('access_token');

    if (state && token) {
      const [country, accountId] = state.split('.');
      settingsStore.setCredentials({ country, accountId, token });

      window.localStorage.setItem(COUNTRY_ITEM, country);
      window.localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
      window.localStorage.setItem(TOKEN_ITEM, token);

      resolve();
      return;
    }

    reject();
  });
};

const getIdamUrl = (country: string, accountId: string): string => {
  const nonce = crypto.randomUUID();
  const baseUrl = IDAM_AUTH.BASE_URL;

  const queryParams = {
    response_type: IDAM_AUTH.RESPONSE_TYPE,
    scope: IDAM_AUTH.SCOPE,
    client_id: IDAM_AUTH.CLIENT_ID,
    realm_id: IDAM_AUTH.REALM_ID,
    redirect_uri: window.location.origin,
    user_type: IDAM_AUTH.USER_TYPE,
    state: `${country}.${accountId}`,
    nonce,
  };

  return `${baseUrl}?${new URLSearchParams(queryParams)}`;
};

// Authenticates the logged in user in an in-app browser flow based on credentials passed from the app
export const authenticateUser = (): Promise<void> =>
  new Promise<void>(async (resolve, reject) => {
    const authUrl = new URL(window.location.href);
    const searchParams = authUrl.searchParams;

    // check for authentication errors in the URL
    if (searchParams.has('error')) {
      reject(
        'Authentication error in url ' + searchParams.get('error_description')
      );
      return;
    }

    // check if local storage credentials are valid
    if (areCredentialsValid()) {
      settingsStore.setCredentials({
        country: getCountry()!,
        accountId: getAccountId()!,
        token: getToken()!,
      });
      resolve();
      return;
    }

    // handle search parameters in the URL before IDAM redirect
    if (searchParams.toString()) {
      const country = searchParams.get(COUNTRY_ITEM);

      const accountId = [...searchParams.entries()]
        .find(([key]) => key.toLowerCase() === ACCOUNT_ID_ITEM.toLowerCase())
        ?.at(1);

      if (country && accountId) {
        // pass through country and accountId – they will be returned by IDAM in hash
        window.location.replace(getIdamUrl(country.toLowerCase(), accountId));
        return;
      }
      reject('Missing authentication credentials');
    }

    // handle url hash after IDAM redirect
    if (authUrl.hash) {
      try {
        await setCredentials(authUrl.hash);
        resolve();
      } catch {
        reject('Missing access token or state in auth url');
      }
    }
    reject('Authentication failed');
  });
