<script lang="ts">
  import { t } from 'svelte-i18n';
  import Icon from './Icon.svelte';
  import Button from './Button.svelte';

  export let showErrorDialog = false;
  export let onCloseClick: () => void;
  export let onRetryClick: () => void;

  let dialog: HTMLDialogElement;

  $: if (dialog && showErrorDialog) dialog.showModal();
  $: if (dialog && !showErrorDialog) dialog.close();
</script>

<dialog
  data-cy="repayment-error-dialog"
  class="absolute w-auto rounded-xl"
  bind:this={dialog}
>
  <div class="relative flex flex-col py-8">
    <div class="px-6">
      <div class="mb-6 flex justify-center">
        <div
          class="flex h-10 w-10 items-center justify-center rounded-lg bg-semantic-appBackground text-semantic-neutral"
        >
          <Icon iconName="DatabaseWarning" size="20" />
        </div>
      </div>

      <h2
        class="mb-4 text-center text-android-title-large font-medium text-primary-base ios:text-ios-title2 ios:font-semibold"
      >
        {$t('repayment_dialog.title_connection_error')}
      </h2>

      <p
        class="mb-4 text-center text-android-body-medium text-semantic-neutral ios:text-ios-body"
      >
        {$t('repayment_dialog.description_connection_error')}
      </p>
    </div>

    <hr class="border-t border-t-primary-tint90 pt-4" />

    <div class="flex gap-2 px-6">
      <Button
        label={$t('general.cta.cancel')}
        type="outlined"
        onClick={onCloseClick}
      />
      <Button label={$t('general.cta.retry')} onClick={onRetryClick} />
    </div>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
</style>
