<script lang="ts">
  import { isLoading } from 'svelte-i18n';
  import TopBar from '../components/TopBar.svelte';
  import ErrorDialog from '../components/ErrorDialog.svelte';
  import { errorDialogStore } from '../../store/errorDialogStore';
  import { isAndroidWebView } from '../utils/detectPlatform';
  import { topBar } from '../../store/TopBarStore';
  import { Context } from '../utils/analytics/keys';
  import { colors } from '../../assets/styles/cssColors';
  import { bodyScrollLock } from '../../store/UiStore';

  $: {
    if ($topBar.context === Context.InvoiceDetails) {
      document.body.style.background = colors.white;
    } else {
      document.body.style.background = colors.semantic.appBackground;
    }
  }

  $: {
    if ($bodyScrollLock) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }
</script>

<!-- loader for the i18n-->
{#if $isLoading}
  <div></div>
{:else}
  {#if !isAndroidWebView()}
    <TopBar />
  {/if}

  <main class={!isAndroidWebView() ? 'pt-topBar' : 'pt-0'}>
    <slot />
  </main>

  <ErrorDialog
    bind:showErrorDialog={$errorDialogStore.showDialog}
    onRetry={$errorDialogStore.actions}
  />
{/if}
