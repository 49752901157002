<script lang="ts">
  import {
    onDestroy,
    onMount,
    type ComponentType,
    type SvelteComponent,
  } from 'svelte';
  import type { Invoice, PodInvoice } from '../../types/mpay.types';
  import BottomSheet from './BottomSheet.svelte';
  import InvoiceNumberAndDate from './InvoiceNumberAndDate.svelte';
  import InvoicePageIndicator from './InvoicePageIndicator.svelte';
  import { navigate } from 'svelte-routing';
  import { routes } from '../../routes';
  import InvoiceHeaderContainer from './InvoiceHeaderContainer.svelte';
  import InvoicePageDots from './InvoicePageDots.svelte';
  import Icon from './Icon.svelte';
  import { bodyScrollLock } from '../../store/UiStore';

  export let invoice: Invoice | PodInvoice;
  export let isOpen: boolean;
  export let setIsOpen: () => void;

  let pdfComponent: ComponentType<SvelteComponent>;
  let isPdfComponentLoading = true;
  let isPdfFileLoaded = false;
  let currentPage = 1;
  let pageCount = 1;

  $: bodyScrollLock.set(isOpen);

  onMount(async () => {
    if (!invoice) return navigate(routes.home.path);

    try {
      const loadedComp = await import('../components/PdfViewer.svelte');
      pdfComponent = loadedComp.default;
    } catch {
      console.error('Failed to load PDF viewer component');
    } finally {
      isPdfComponentLoading = false;
      // not calling hideLoader(); to make the "loader" experience smooth. It will be hidden from the PdfViewer component
      // loader has a "safety" timeout of 15 seconds
    }
  });

  onDestroy(() => bodyScrollLock.set(false));

  const onPdfLoaded = (e: { detail: number }) => {
    pageCount = e.detail;
    isPdfFileLoaded = true;
  };
</script>

<BottomSheet {isOpen} {setIsOpen} tinted>
  {#if invoice}
    <div class="inline-flex w-full flex-col gap-4">
      <InvoiceHeaderContainer>
        <div>
          <InvoiceNumberAndDate {invoice} forcePodTitle />
        </div>

        <InvoicePageIndicator {currentPage} {pageCount} />
      </InvoiceHeaderContainer>

      <div
        style={`height: ${window.innerHeight * 0.7}px`}
        class="relative overflow-x-scroll"
      >
        {#if isPdfComponentLoading}
          <div
            class="flex h-full items-center justify-center text-primary-base"
          >
            <Icon iconName="Spinner" />
          </div>
        {:else}
          <div
            class={[
              `absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 text-primary-base`,
              `${isPdfFileLoaded ? 'hidden' : 'visible'}`,
            ].join(' ')}
          >
            <Icon iconName="Spinner" />
          </div>

          <svelte:component
            this={pdfComponent}
            href={invoice.pdfLink}
            on:load={onPdfLoaded}
            on:pageChange={(e) => (currentPage = e.detail)}
          />
        {/if}
      </div>

      <InvoicePageDots {pageCount} {currentPage} />
    </div>
  {/if}
</BottomSheet>
