import { writable } from 'svelte/store';

const createRepaymentStore = () => {
  const initialState = {
    repaymentAmount: '',
  };
  const { subscribe, update } = writable(initialState);

  return {
    subscribe,
    setRepaymentAmount: (value: string) =>
      update((state) => ({ ...state, repaymentAmount: value })),
  };
};

export const repaymentStore = createRepaymentStore();
