<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { t } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { getFormattedNumber } from '../utils/formatters';
  import Button from '../components/Button.svelte';
  import Input from '../components/Input.svelte';
  import { topBar } from '../../store/TopBarStore';
  import { Context, Target } from '../utils/analytics/keys';
  import { sendRequest } from '../utils/fetch';
  import { MPAY_API_BASE_URI } from '../../env';
  import { MPayApi } from '../constants';
  import RepaymentDialog from '../components/RepaymentDialog.svelte';
  import Icon from '../components/Icon.svelte';
  import { mCreditStore } from '../../store/mCreditStore';
  import { settingsStore } from '../../store/settingsStore';
  import { getCurrencyUnit } from '../utils/helpers';
  import featureFlagsStatus from '../utils/featureFlags';
  import RepaymentPlaceholder from '../components/RepaymentPlaceholder.svelte';
  import { navigate } from 'svelte-routing';
  import { routes } from '../../routes';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { posthog } from '../../plugins/posthog';
  import SelectedBankTile from '../components/SelectedBankTile.svelte';
  import { navigateBack } from '../JSBridge/navigationController';
  import RepaymentErrorDialog from '../components/RepaymentErrorDialog.svelte';
  import { repaymentStore } from '../../store/repaymentStore';

  let inputError: null | string = null;
  let showRepaymentDialog = false;
  let showErrorDialog = false;
  let isPaymentLinkLoading = false;

  const currencyCode = getCurrencyUnit($mCreditStore.creditLimit.currencyCode);
  const initialValues = {
    dueAmount: $mCreditStore.mCreditSummary.dueAmount,
    overdueAmount: $mCreditStore.mCreditSummary.overdueAmount,
    usedLimit: $mCreditStore.creditLimit.exhaustedCreditLimit,
    availableLimit: $mCreditStore.creditLimit.availableCreditLimit,
  };

  let dueAmount = initialValues.dueAmount;
  let overdueAmount = initialValues.overdueAmount;
  let usedLimit = initialValues.usedLimit;
  let availableLimit = initialValues.availableLimit;
  // let maxRepaymentAmount = initialValues.dueAmount + initialValues.overdueAmount;

  $: isButtonDisabled =
    inputError !== null ||
    Number($repaymentStore.repaymentAmount) <= 0 ||
    isNaN(Number($repaymentStore.repaymentAmount));

  function handleRepaymentChange(input: string) {
    inputError = null;
    const value = Number(input);

    if (value <= 0 || isNaN(value)) {
      inputError = `${$t('repayment.error_min_amount')} ${currencyCode}`;
      resetAmounts();
    }

    // TODO: restore after initial prod tests
    // if (Number(input) > maxRepaymentAmount) {
    //   inputError = `${$t('repayment.error_max_amount')} ${getFormattedNumber(maxRepaymentAmount)} ${currencyCode}`;
    // }

    updateAmounts(value);
  }

  function storeRepaymentAmount(input: string) {
    const value = Number(input);

    if (value > 0 && !isNaN(value)) {
      repaymentStore.setRepaymentAmount(Number(input).toFixed(2));
    }
  }

  function resetAmounts() {
    dueAmount = initialValues.dueAmount;
    overdueAmount = initialValues.overdueAmount;
    usedLimit = initialValues.usedLimit;
    availableLimit = initialValues.availableLimit;
  }

  function updateAmounts(value: number) {
    const remainingAmount = Math.max(0, value - initialValues.overdueAmount);
    overdueAmount = Math.max(0, initialValues.overdueAmount - value);
    dueAmount = Math.max(0, initialValues.dueAmount - remainingAmount);
  }

  async function handlePaymentLink() {
    isPaymentLinkLoading = true;

    try {
      const { country, accountId } = $settingsStore.credentials;
      const response = await sendRequest<{ web_url: string }>(
        `${MPAY_API_BASE_URI}/${MPayApi.PAYMENTS}/links/create`,
        {
          method: 'POST',
          body: JSON.stringify({
            accountId,
            amount: $repaymentStore.repaymentAmount,
            country,
          }),
        }
      );

      window.location.replace(response.web_url);
    } catch {
      showErrorDialog = true;
    } finally {
      showRepaymentDialog = false;
      isPaymentLinkLoading = false;
    }
  }

  function openRepaymentDialog() {
    showErrorDialog = false;
    showRepaymentDialog = true;
  }

  function handleContinue() {
    if (isButtonDisabled) return;
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Repayment,
      target: Target.RepaymentContinue,
    });

    if (!$settingsStore.selectedBank) {
      return navigate(routes.repaymentBankSelection.path);
    }

    openRepaymentDialog();
  }

  onMount(() => {
    if (!featureFlagsStatus.repayment) {
      return navigateBack();
    }
    posthog.capture('$pageview');

    const repaymentAmount = (
      ($mCreditStore.mCreditSummary.dueAmount ?? 0) +
      ($mCreditStore.mCreditSummary.overdueAmount ?? 0)
    ).toFixed(2);

    if (!$repaymentStore.repaymentAmount) {
      repaymentStore.setRepaymentAmount(repaymentAmount);
    }

    topBar.setTitle($t('repayment.title'));
    topBar.setContext(Context.Repayment);
    topBar.setShowBackButton(true);

    updateAmounts(Number($repaymentStore.repaymentAmount));
  });

  onDestroy(() => {
    topBar.reset();
  });
</script>

{#if featureFlagsStatus.repayment}
  <Layout>
    <div class="bg-primary-darken20 px-5 pb-6 pt-8">
      <h1
        class="mb-6 text-android-headline-small font-medium text-semantic-inverted ios:text-ios-title1 ios:font-semibold"
      >
        {$t('repayment.header')}
      </h1>

      <div class="mb-6">
        <Input
          id="repayment-amount"
          type="number"
          label={$t('repayment.total')}
          value={$repaymentStore.repaymentAmount}
          {currencyCode}
          onChange={handleRepaymentChange}
          onBlur={storeRepaymentAmount}
          error={inputError}
        />
      </div>

      <Button
        label={$t('general.cta.continue')}
        onClick={handleContinue}
        type="default"
        isDisabled={isButtonDisabled}
      />
    </div>

    <SelectedBankTile />

    <section class="mx-5 mt-6 grid gap-3">
      <p
        class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
      >
        {$t('repayment.balance_after_header')}
      </p>

      <div class="rounded-xl bg-semantic-inverted px-4 py-1">
        <ul class="text-primary-base ios:text-ios-callout">
          <li
            class="flex h-[52px] items-center justify-between border-b border-primary-tint90"
          >
            <span>{$t('mcredit.due_amount')}</span>
            <span class="font-semibold" data-cy="due-amount-holder">
              {getFormattedNumber(dueAmount)}
              {currencyCode}
            </span>
          </li>
          <li
            class="flex h-[52px] items-center justify-between border-b border-primary-tint90"
          >
            <span>{$t('mcredit.overdue_amount')}</span>
            <span
              class={`flex items-center font-semibold ${overdueAmount > 0 ? 'text-red-base' : ''}`}
              data-cy="overdue-amount-holder"
            >
              {#if overdueAmount > 0}
                <span class="mr-2" data-cy="overdue-amount-icon">
                  <Icon iconName="Warning" size="16" />
                </span>
              {/if}

              {getFormattedNumber(overdueAmount)}
              {currencyCode}
            </span>
          </li>
          <li
            class="flex h-[52px] items-center justify-between border-b border-primary-tint90"
          >
            <span>{$t('mcredit.used_limit')}</span>
            <span class="font-semibold">
              {getFormattedNumber(usedLimit)}
              {currencyCode}
            </span>
          </li>
          <li class="flex h-[52px] items-center justify-between">
            <span>{$t('mcredit.available_limit')}</span>
            <span class="font-semibold">
              {getFormattedNumber(availableLimit)}
              {currencyCode}
            </span>
          </li>
        </ul>
      </div>

      <RepaymentDialog
        {showRepaymentDialog}
        {isPaymentLinkLoading}
        onContinueClick={handlePaymentLink}
        onCloseClick={() => (showRepaymentDialog = false)}
      />

      <RepaymentErrorDialog
        {showErrorDialog}
        onRetryClick={openRepaymentDialog}
        onCloseClick={() => (showErrorDialog = false)}
      />
    </section>
  </Layout>
{:else}
  <RepaymentPlaceholder />
{/if}
