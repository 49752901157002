<script lang="ts">
  import { t } from 'svelte-i18n';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';
  import { Link } from 'svelte-routing';
  import { getFormattedTimestamp } from '../utils/formatters';
  import type { Invoice } from '../../types/mpay.types';
  import Icon from './Icon.svelte';
  import Badge from './Badge.svelte';
  import {
    CustomerInvoiceType,
    InvoiceType,
    PaymentStatus,
  } from '../constants';
  import { getPaymentMethodDescription } from '../utils/paymentCodes';

  export let invoice: Invoice;
  export let href: string = '';
  export let isMcreditList = false;
  export let onClick: () => void;

  let name: string = '';
  let formattedDateTime: string = '';

  $: showMcreditStaus =
    isMcreditList &&
    invoice.mCreditPaymentStatus &&
    invoice.mCreditPaymentStatus !== PaymentStatus.DUE;
  $: isFsd =
    invoice.customerInvoiceType === CustomerInvoiceType.POD ||
    invoice.customerInvoiceType === CustomerInvoiceType.COD;
  $: {
    name = invoice.cardHolder?.firstName + ' ' + invoice.cardHolder?.lastName;
    formattedDateTime = getFormattedTimestamp(invoice.date);
  }
</script>

<li
  class="border-b border-primary-tint90 px-5 last:border-0 active:bg-primary-tint90"
  data-cy="invoices-list-item"
>
  <Link
    to={href}
    on:click={onClick}
    class="grid grid-cols-[40px_minmax(0,1fr)_80px] items-center gap-3 pb-[15px] pt-[17px]"
  >
    <div
      class="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 text-semantic-neutral shadow-small"
    >
      <Icon
        iconName={invoice.invoiceType === InvoiceType.LEGA
          ? 'Business'
          : 'Cardholder'}
      />
    </div>

    <div class="w-max-1/2 flex w-full flex-col">
      <div class="flex items-center">
        <time
          datetime={formattedDateTime}
          class="mr-2 text-android-title-medium text-primary-base ios:text-ios-headline ios:font-semibold"
        >
          {formattedDateTime}
        </time>

        {#if showMcreditStaus}
          <Badge type={invoice.mCreditPaymentStatus} />
        {/if}
      </div>

      <div class="flex">
        <p
          class="mr-1 truncate text-android-body-small text-primary-base ios:text-ios-footnote"
        >
          {invoice.customerInvoiceType === CustomerInvoiceType.COD
            ? $t('payment_type.cod')
            : getPaymentMethodDescription(invoice.paymentList)}
        </p>
        <p
          class="truncate text-android-body-small text-primary-tint40 ios:text-ios-footnote"
        >
          {name}
        </p>
      </div>
    </div>

    <div class="flex flex-col items-end justify-end">
      <!-- MCREDIT TAB: showing only MCredit amounts -->
      {#if isMcreditList}
        <ListInvoiceAmount
          isMain
          amount={invoice.paymentList.find((item) => item.paymentCode === 600)
            ?.paymentAmount || null}
        />
        <!-- FSD (POD+COD) - no net value, instead showing icon+label -->
        {#if isFsd}
          <span
            class="flex gap-1 text-android-body-small text-primary-tint40 ios:text-ios-footnote"
          >
            <Icon iconName="Delivery" size="16" />
            {$t('invoice.delivery')}
          </span>

          <!-- Multiple payments - no mcredit net value, instead showing icon+label -->
        {:else if invoice.paymentList.length > 1}
          <span
            class="flex gap-1 truncate text-android-body-small text-primary-tint40 ios:text-ios-footnote"
          >
            <Icon iconName="StoreMakro" size="16" />
            {$t('invoice.in_store')}
          </span>

          <!-- MCredit only payments - show available net value -->
        {:else}
          <ListInvoiceAmount amount={invoice.netAmount} type={'netto'} />
        {/if}

        <!-- INVOICES TAB -->
      {:else}
        <ListInvoiceAmount
          isMain
          amount={invoice.grossAmount}
          type={invoice.grossAmount < 0 ? 'voucher' : 'total'}
        />

        <ListInvoiceAmount amount={invoice.netAmount} type={'netto'} />
      {/if}
    </div>
  </Link>
</li>
