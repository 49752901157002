import { t } from 'svelte-i18n';
import { get } from 'svelte/store';
import type { Payment } from '../../types/mpay.types';

export const getPaymentMethodLocalisedString = (
  paymentCode: number | string
): string => {
  if (!paymentCode) return get(t)('general.unknown');

  switch (paymentCode.toString()) {
    case '1':
      return get(t)('payment_type.cash');
    case '200':
      return get(t)('payment_type.cec');
    case '205':
      return get(t)('payment_type.sponsorship');
    case '206':
      return get(t)('payment_type.payment_order');
    case '207':
      return get(t)('payment_type.equipments_rates');
    case '208':
      return get(t)('payment_type.cash_deposit');
    case '209':
      return get(t)('payment_type.storno');
    case '300':
    case '301':
    case '380':
    case '400':
      return get(t)('payment_type.card');
    case '600':
      return get(t)('payment_type.mcredit');
    case '602':
      return get(t)('payment_type.internal_consumption');
    case '700':
      return get(t)('payment_type.transport_guarantees');
    case '800':
    case '801':
    case '1500':
      return get(t)('payment_type.packaging_voucher');
    case '803':
      return get(t)('payment_type.srg_voucher');
    case '810':
    case '811':
      return get(t)('payment_type.return_voucher');
    case '900':
    case '902':
    case '903':
      return get(t)('payment_type.gift_voucher');
    case '901':
      return get(t)('payment_type.discount');
    case '904':
    case '905':
    case '1000':
      return get(t)('payment_type.voucher');
    case '920':
      return get(t)('payment_type.meal_ticket');
    case '950':
      return get(t)('payment_type.mplus_points');
    case '952':
      return get(t)('payment_type.loyalty_points');
    case '1200':
      return get(t)('payment_type.compensation');
    case '1300':
      return get(t)('payment_type.tree_parties');
    case 'other':
    default:
      return get(t)('payment_type.other');
  }
};

export const getPaymentMethodDescription = (paymentList: Payment[]): string => {
  return paymentList.length > 1
    ? get(t)('payment_type.multiple')
    : getPaymentMethodLocalisedString(paymentList[0]?.paymentCode);
};
