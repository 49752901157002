<script lang="ts">
  import { t } from 'svelte-i18n';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { Target } from '../utils/analytics/keys';
  import { invoicesStore } from '../../store/invoicesStore';
  import Chip from './Chip.svelte';
  import PaymentMethodSheet from './PaymentMethodSheet.svelte';
  import { topBar } from '../../store/TopBarStore';
  import { onDestroy } from 'svelte';
  import { bodyScrollLock } from '../../store/UiStore';

  let isVisible = false;
  export let onSelect: (...args: unknown[]) => unknown;

  $: bodyScrollLock.set(isVisible);

  onDestroy(() => bodyScrollLock.set(false));

  const toggleVisibility = () => {
    isVisible = !isVisible;
  };

  const onClick = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: $topBar.context,
      target: Target.PaymentMethodFilter,
    });

    toggleVisibility();
  };

  const onMethodSelect = () => {
    toggleVisibility();
    onSelect();
  };
</script>

<Chip
  id="payment-type-button"
  on:click={onClick}
  withChevron
  isActive={$invoicesStore.filters.paymentMethod.length > 0}
>
  <p class="truncate">
    {$t('invoice_details.payment_method')}
  </p>
</Chip>

<PaymentMethodSheet
  isOpen={isVisible}
  setIsOpen={toggleVisibility}
  onSelect={onMethodSelect}
/>
