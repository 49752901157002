import { jwtDecode } from 'jwt-decode';
import posthog from 'posthog-js';
import { getToken } from './localStorage';
import { settingsStore } from '../../store/settingsStore';
import { get } from 'svelte/store';

async function hashValueWithSha256(value: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}

export async function setPosthogUniqueId() {
  const token = getToken();

  if (token) {
    try {
      const userData: { email: string } = await jwtDecode(token);
      const uniqueId = await hashValueWithSha256(userData.email);

      const settings = get(settingsStore);
      const customerIdHash = await hashValueWithSha256(
        settings.accountDetails.normalizedCustomerId
      );

      posthog.identify(uniqueId, {
        customer_id_hash: customerIdHash.substring(0, 16),
      });
    } catch {
      throw new Error('Invalid token');
    }
  }
}
