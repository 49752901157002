<script lang="ts">
  import { t } from 'svelte-i18n';
  import { isAndroidWebView } from '../utils/detectPlatform';
  import Button from './Button.svelte';

  export let goBack: () => void;
  export let goToDetails: () => void;
</script>

{#if isAndroidWebView()}
  <div class="flex flex-col gap-3 pt-4">
    <Button label={$t('buttons.back_to_overview')} onClick={goBack} />

    <Button
      label={$t('buttons.payment_details')}
      type="outlined"
      onClick={goToDetails}
    />
  </div>
{/if}
